<template>
    <el-table
        :data="tableData">
        <el-table-column
            v-for="(item, index) in tableColumn"
            :key="item.label"
            :label="item.label"
            prop="spec">
            <template #default="{ row }">
                <div>{{row.spec[index]}}</div>
            </template>
        </el-table-column>
        <el-table-column
            label="价格"
            prop="price">
            <template #default="{ row }">
                <el-input
                    v-model="row.price">
                </el-input>
            </template>
        </el-table-column>
    </el-table>
</template>

<script>
    export default {
        props: {
            tableColumn: {
                type: Array,
                default: () => { return [] }
            },
            tableData: {
                type: Array,
                default: () => { return [] }
            }
        }
    }
</script>

<style scoped>

</style>